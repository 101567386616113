// Created By: artur

import { html, LitElement, nothing } from 'lit';
import { property } from 'lit/decorators.js';

// styles
import styles from './aurora-feature-card-css';
import global from '../../styles/global-css';
import { classMap } from 'lit/directives/class-map.js';

interface FeatureItem {
  link: string;
  label: string;
}

export class AuroraFeatureCard extends LitElement {
    @property({ type: Object })
    data: {
        title: string;
        description: string;
        featureTitle: string;
        items: FeatureItem[];
        url: string;
        openInNewWindow: boolean;
    }

    static get styles() {
      return [styles, global];
    }

    render() {
        const { title,
                description,
                items,
                featureTitle,
                url,
                openInNewWindow
              } = this.data;
        return html`
          <section class="aurora-feature-card">
            <a href="${url}"
              target="${openInNewWindow ? '_blank' : '_self'}"
              class="aurora-feature-section">
                <h4>
                  ${title}
                </h4>
                
                <p class="${classMap({ 
                  'small': items?.length > 0 ,
                })}">
                  ${description}
                </p>
            </a>

            ${items?.length > 0 
              ? html`
                <div class="aurora-feature-section grey">
                  ${featureTitle 
                    ? html`
                      <span class="feature-title">
                        ${featureTitle}
                      </span>
                    `
                    : nothing
                  }
                  
                  <ul>
                    ${items.slice(0, 3).map((item) =>
                      html`
                        <li>
                          <a href="${item.link}" target="_blank">
                            ${item.label}
                          </a>
                        </li>
                      `
                    )}
                  </ul>
                </div>
              ` : nothing
            }
          </section>`
    }

}
