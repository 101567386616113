// Created By: Joe Kanakaraj

import { html, LitElement, nothing } from 'lit';
import { property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';

// styles
import styles from './aurora-mosaic-tiles-css';
import global from '../../styles/global-css';
import { Image } from '../../types/types';
import { domPurify } from '../../utils/domPurify';


export class AuroraMosaicTiles extends LitElement {
  @property({ type: String })
  position?: 'top' | 'middle' | 'bottom';

  @property({ type: String })
  alignment?: 'left' | 'center' | 'right';

  @property({ type: String })
  textSize?: 'small' | 'large';

  @property()
  hasOverlay?: string;

  @property({ type: Object })
  data: {
    gradient?: 'tertiary-3'|'tertiary-4'|'neutral'|'highlight'
    title: string;
    href: string;
    background?: Image;
    icon?: Image;
  };

  static get styles() {
    return [styles, global];
  }

  connectedCallback() {
    super.connectedCallback();
  }
  get _textSize() {
    if (this.data?.gradient) return 'small';

    if (!this.textSize) return 'large';

    return this.textSize;
  }


  get _position() {
    if (this.data?.gradient && this.data?.gradient !== 'highlight') return 'middle';

    if (!this.position) return 'middle';

    if (this.data?.icon) return 'middle';

    return this.position;
  }


  get _alignment() {
    if (this.data?.gradient && this.data?.gradient !== 'highlight') return 'center';

    if (!this.alignment) return 'center';
    if (this.data?.icon) return 'center';

    return this.alignment;
  }

  renderIcon() {
    if (!this.data?.icon) {
      return nothing;
    }

    if (this.data.icon.contentType?.toUpperCase() === 'SVG') {
      const safeString = domPurify(this.data.icon.imageSrc);
      return html `<slot .innerHTML=${safeString}></slot>`
    }

    return html`<img src="${this.data.icon.imageSrc}" alt="${this.data.icon.altText}" />`
  }

  render() {
    if (!this.data) return nothing
    const { title, href, background, gradient } = this.data;
    // const withOverlay = /true/i.test(this.hasOverlay);

    return html`
      <a href="${href}" class="mosaic-tiles-item-container" tabindex="-1">

        ${gradient ? html`
          <div class="mosaic-tiles-bg-gradient mosaic-${gradient}"></div>
        ` : html`
          <div
            role="img"
            aria-label="${background?.altText}"
            style="background-image: url(${background?.imageSrc})"
            class=${classMap({ 
              'mosaic-tiles-bg-image': true,
              // 'with-overlay': withOverlay 
            })}
          ></div>
        `}
        

        <div
          class=${classMap({ 
            [`mosaic-tiles mosaic-tiles-${this._alignment} mosaic-tiles-${this._position}`]: true,
            'bg-gradient': !gradient 
          })}
        >
          ${this.renderIcon()}

          <span
            class=${classMap({ 
              [`mosaic-tiles-link mosaic-tiles-link-${this._textSize}`]: true,
              'text-primary': !!gradient && gradient !== 'highlight',
            })}
          >
            ${title}
          </span>
        </div>

        <svg xmlns="http://www.w3.org/2000/svg" style="position: absolute; width: 0; height: 0">
          <defs>
            <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop offset="0%" style="stop-color: #9b32aa" />
              <stop offset="100%" style="stop-color: #512d6d" />
            </linearGradient>
          </defs>
        </svg>
      </a>
    `;
  }
}
