// Created By: Victor Tommasi

import { html, LitElement, nothing } from 'lit';
import { property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';

// styles
import styles from './aurora-carousel-pagination-css';
import global from '../../styles/global-css';
import typography from '../../styles/typography-css';

export class AuroraCarouselPagination extends LitElement {
  @property({ type: Object })
  data: {
    currentIndex: number,
    numberOfElements: number,
    lastIndex: number,
    showOnDesktop?: boolean,
    visible?: boolean,
  };

  constructor() {
    super();
    this.data = {
      currentIndex: 0,
      numberOfElements: 0,
      lastIndex: 0,
      showOnDesktop: false,
      visible: true,
    }
  }

  static get styles() {
    return [styles, global, typography];
  }

  private handlePageClick(pageIndex: number): void {
    this.dispatchEvent(new CustomEvent('page-change', {
      detail: { currentIndex: pageIndex }
    }));
  }

  render() {
    return this.data.visible ? html`
      <div class=${classMap({
        'aurora-carousel-pagination': true,
        'show-desktop': this.data.showOnDesktop,
      })}>
        ${Array.from({ length: Math.min(6, this.data.numberOfElements) }).map((_, index) => {
          const pageIndex = this.data.currentIndex - 2 + index;
          const isOutOfRange = pageIndex < 0 || pageIndex > this.data.lastIndex;
          const isActive = pageIndex === this.data.currentIndex;
          const isVisible = !isOutOfRange && pageIndex >= 0 && pageIndex < this.data.numberOfElements;
          const isAtEdge = pageIndex === 0 || pageIndex === this.data.lastIndex;
          const isCurrentIndexNotAtEdge = this.data.currentIndex > 0 && this.data.currentIndex < this.data.lastIndex;
          const isDotNotAtEdge = pageIndex === 1 || pageIndex === this.data.lastIndex - 1;
          const isExtraSmall = isVisible && !isActive && isAtEdge;
          const isSmall = !isActive && isCurrentIndexNotAtEdge && isDotNotAtEdge;

          return html`
            <div class=${classMap({
                'pagination-dot': true,
                'active': isActive,
                'xsmall': isExtraSmall,
                'small': isSmall,
                'hidden': isOutOfRange,
              })}
              role="button"
              aria-label="Page ${pageIndex + 1}"
              aria-current=${isActive ? 'page' : nothing}
              @click=${() => this.handlePageClick(pageIndex)}>
            </div>
          `;
        })}
      </div>
      `: nothing
  }
}
