// Created By: Mateo Trujillo

import { html, LitElement, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { map } from 'lit/directives/map.js';
import { domPurify } from '../utils/domPurify';

// styles
import styles from './aurora-horizontal-tab-panel-css';
import global from '../styles/global-css';
import typography from '../styles/typography-css';

//types
import {  ITabPanelData } from '../aurora-tab-panel/src/auroraTabPanel';

@customElement('aurora-horizontal-tab-panel')
export class AuroraHorizontalTabPanel extends LitElement {
  @property({ type: Object })
  data: ITabPanelData;

  static get styles() {
    return [styles, global, typography];
  }

  connectedCallback() {
    super.connectedCallback();
  }

  render() {
    const { title, description, image, buttons, imagePosition } = this.data;
    
    if(!this.data.imagePosition) {
      this.data.imagePosition = 'left';
    }

    return html`
      <article class="aurora-horizontal-tab-panel ${imagePosition}">
        ${image
          ? html`
            <div class="aurora-horizontal-tab-panel__image">
              <picture>
                ${image?.croppings?.length > 0
                  ? map(image?.croppings, (crop) => html` <source srcset="${crop.imageSrc}" media="${crop.srcSet}" />`)
                  : nothing}
                <img src="${image?.original?.imageSrc}" alt="${image?.original?.altText}" />
              </picture>
            </div>`
          : nothing}
        <div class="aurora-horizontal-tab-panel__content">
          <h3 class="aurora-horizontal-tab-panel__title">${title}</h3>
          <p class="aurora-horizontal-tab-panel__description" 
            .innerHTML= ${domPurify(description)}>
          </p>
          <div class="aurora-horizontal-tab-panel__cta">
            ${buttons?.length > 0
              ? html` ${map(buttons, (btn: object) => html` <aurora-text-button .textdata="${btn}"></aurora-text-button>`)}`
              : nothing}
          </div>
      </article>
    `
  }
}
