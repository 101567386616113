// Created By: Mike Baker
import { html, LitElement } from 'lit';
import { property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';

// Styles
import styles from './aurora-link-button-css';

// Types
import { IButton } from '../../types/types';

export class AuroraLinkButton extends LitElement {
  @property({ type: Object })
  buttondata: IButton;

  static get styles() {
    return [styles];
  }

  connectedCallback() {
    super.connectedCallback();
  }

  render() {
    const { buttonStyles, openInNewWindow, url, title, icon, autoWidth, rel, disabled } = this.buttondata;
    const classes = {
      'aurora-btn': true,
      [buttonStyles.join(' ')]: true,
      'aurora-btn-auto-width': autoWidth,
      'disabled': disabled,
    }
    return html` <a
      class="${classMap(classes)}"
      target="${openInNewWindow ? '_blank' : '_self'}"
      href="${url}"
      rel=${rel}
    >
      ${title} ${icon?.imageSrc && html`<img src="${icon.imageSrc}" alt="${icon.altText}" />`}
    </a>`;
  }
}
