// Created By: Mike Baker

import { html, LitElement, nothing } from 'lit';
import { property } from 'lit/decorators.js';

// styles
import styles from './aurora-standard-card-css';
import global from '../../styles/global-css';

// Types
import { Image, IButton } from '../../types/types';
import { getAttributeData } from '../../utils/getAttributeData';

export class AuroraStandardCard extends LitElement {
  @property({ type: Object, attribute: false })
  cardData: {
    title?: string;
    subtitle?: string;
    description?: string;
    image?: Image;
    button?: Omit<IButton, 'buttonStyles'>;
  };

  static get styles() {
    return [styles, global];
  }

  connectedCallback() {
    super.connectedCallback();
    if (this.getRootNode() instanceof ShadowRoot) {
      return;
    }
    this.cardData = getAttributeData.call(this, 'cardData');
  }

  render() {
    const { title, subtitle, image, description, button } = this.cardData;

    return html` <div class="standard-card">
       ${image ? html`<aurora-img .data="${{ objectFit: 'cover', ...image  }}"></aurora-img>` : nothing}
      <div class="caption">
      ${subtitle ? html`<span class="eyebrow">${subtitle}</span>` : nothing}
      <h2 class="card-header">${title}</h2>
      ${description
        ? html` <div class="card-body">
          <p>${description}</p>
        </div>`
        : nothing}
      ${button
        ? html` <div class="call-to-action">
          <aurora-text-button .textdata="${button}"></aurora-text-button>
        </div>`
        : nothing}
      </div>
    </div>`;
  }
}
