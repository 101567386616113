// Created By: Mateo Trujillo
import { html, LitElement, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { map } from 'lit/directives/map.js';
import { domPurify } from '../utils/domPurify';

// styles
import styles from './aurora-vertical-tab-panel-css';
import global from '../styles/global-css';
import typography from '../styles/typography-css';

// types
import {  ITabPanelData } from '../aurora-tab-panel/src/auroraTabPanel';

// components
import '../aurora-link-button/index';

@customElement('aurora-vertical-tab-panel')
export class AuroraVerticalTabPanel extends LitElement {
  @property({ type: Object })
  data: ITabPanelData;

  static get styles() {
    return [styles, global, typography];
  }

  render() {
    const { title, description, image, buttons, imagePosition } = this.data || {};
      return html`
        <article class="aurora-vertical-tab-panel ${imagePosition}">
        ${imagePosition === 'top' ? html`
          ${image 
            ? html`
              <div class="aurora-tab-panel__image">
                <picture>
                  ${image?.croppings?.length > 0
                    ? map(image?.croppings, (crop) => html` <source srcset="${crop.imageSrc}" media="${crop.srcSet}" />`)
                    : nothing}
                  <img src="${image?.original?.imageSrc}" alt="${image?.original?.altText}" />
                </picture>
              </div>`
            : nothing}
            <h3 class="aurora-tab-panel__title">${title}</h3>
            <p class="aurora-tab-panel__description" .innerHTML= ${domPurify(description)}></p>
          ` : 
          html `
            <h3 class="aurora-tab-panel__title">${title}</h3>
            <p class="aurora-tab-panel__description">
            ${image 
              ? html`
                <div class="aurora-tab-panel__image">
                  <picture>
                    ${image?.croppings?.length > 0
                      ? map(image?.croppings, (crop) => html` <source srcset="${crop.imageSrc}" media="${crop.srcSet}" />`)
                      : nothing}
                    <img src="${image?.original?.imageSrc}" alt="${image?.original?.altText}" />
                  </picture>
                </div>`
              : nothing}
            <slot .innerHTML=${domPurify(description)}></slot>
          </p>
          `}
          <div class="aurora-tab-panel__cta">
            ${buttons?.length > 0 
              ? html` ${map(buttons, (btn) => html` <aurora-link-button 
                .buttondata="${{
                  ...btn,
                  buttonStyles: [...(btn.buttonStyles ?? []), 'full-width-button']}}"
                }}">
                </aurora-link-button>`)}`
              : nothing}
          </div>
        </article>
      `
  }
}
