// Created By: Mateo Trujillo

import { html, LitElement, nothing } from 'lit';
import { property, state } from 'lit/decorators.js';
import { tabIndex } from '../../utils/getTabIndex';

// styles
import styles from './aurora-testimonial-card-css';
import global from '../../styles/global-css';
import { Image } from '../../types/types';
import typography from '../../styles/typography-css';

export class AuroraTestimonialCard extends LitElement {
  @property({ type: Object })
  data: {
    quote: string;
    author: string;
    city: string;
    image?: Image;
    tabindex?: number;
  };

  @property()
  variant: 'white' | 'gradient' | 'gray';

  @state()
  _pageWidth: number = window.innerWidth;

  @state() _isOpen = false;

  private handleClick = (): void => {
    if (this._pageWidth < 992) {
      this._isOpen = !this._isOpen;
    }
  };

  private handleMouseOverAndFocus = (): void => {
    this._isOpen = true;
  };

  private handleMouseOutAndFocusOut = (): void => {
    this._isOpen = false;
  };

  static get styles() {
    return [styles, global, typography];
  }

  connectedCallback() {
    super.connectedCallback();
  }

  render() {
    const { quote, author, city, image } = this.data;

    return html`<section
      @click=${this.handleClick}
      @mouseover=${this.handleMouseOverAndFocus}
      @focus=${this.handleMouseOverAndFocus}
      @mouseout=${this.handleMouseOutAndFocusOut}
      @focusout=${this.handleMouseOutAndFocusOut}
      tabindex=${tabIndex(this.data?.tabindex)}
      class= "testimonial-card ${this.variant}"
    >    
      <div class="testimonal-card__source">
        <p class="testimonal-card__quote">"${quote}"</p>
        <div class="testimonial-card__footer">
          ${image ? html`<aurora-img .data="${{ backgroundImage: true, ...image  }}"></aurora-img>` : nothing}
          <div class="testimonial-card__footer__data">
            <p class="testimonal-card__author">${author}</p>
            <p class="testimonal-card__city">${city}</p>
          </div>
        </div>        
      </div>
    </section>`;
  }
}
