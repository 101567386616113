// Created By: Joe Kanakaraj

import { html, LitElement, nothing } from 'lit';
import { property, state } from 'lit/decorators.js';
import { domPurify } from '../../utils/domPurify';
import { map } from 'lit/directives/map.js';

// styles
import styles from './aurora-stacked-section-hero-block-css';
import global from '../../styles/global-css';

export class AuroraStackedSectionHeroBlock extends LitElement {
  @property({ type: String }) variant?: 'default' | 'media-left';
  @property({ type: String }) background?: 'bg-light-grey' | 'bg-dark-purple' | 'bg-light-purple';
  @property({ type: Object, attribute: false })
  data: {
    title: string;
    description: string;
    buttons: [
      {
        url: '';
        openInNewWindow: false;
        title: '';
        buttonStyles: [''];
      },
    ];
    image: {
      original: {
        imageSrc: '';
        altText: '';
      };
      croppings: [
        {
          imageSrc: '';
          srcSet: '';
        },
      ];
    };
  };
  @state() _loading: boolean;

  static get styles() {
    return [styles, global];
  }

  connectedCallback() {
    super.connectedCallback();

    const attr = this.getAttribute('data');
    if (attr !== null && !Object.keys(attr).includes('loaded')) {
      const data = JSON.parse(this.getAttribute('data'));
      this.data = data;
      this.setAttribute('data', '{"loaded": true}');
    }
  }

  getTemplate() {
    const { title, description, image, buttons } = this.data;
    if (this.variant === 'media-left') {
      return html`
        <div class="w-6col media">
          ${image ? html`<aurora-img .data="${{ backgroundImage: true, ...image  }}"></aurora-img>` : nothing}
        </div>
        <div class="w-6col content">
          <div class="score-section-hero">
            <h2>${title}</h2>
            <div class="score-hero-body">
              <div class="rich-text" .innerHTML=${domPurify(description)}></div>
            </div>
            <div class="score-call-to-action">
              ${buttons.length > 0
                ? html`
                    ${map(
                      buttons,
                      (btn: object) =>
                        html` <aurora-link-button
                          class="stacked-hero-cta"
                          .buttondata="${btn}"
                        ></aurora-link-button>`,
                    )}
                  `
                : nothing}
            </div>
          </div>
        </div>
      `;
    } else {
      return html`
        <div class="w-6col content-left">
          <div class="score-section-hero">
            <h2>${title}</h2>
            <div class="score-hero-body">
              <div class="rich-text" .innerHTML=${domPurify(description)}></div>
            </div>
            <div class="score-call-to-action">
              ${buttons.length > 0
                ? html`
                    ${map(
                      buttons,
                      (btn: object) =>
                        html` <aurora-link-button
                          class="stacked-hero-cta"
                          .buttondata="${btn}"
                        ></aurora-link-button>`,
                    )}
                  `
                : nothing}
            </div>
          </div>
        </div>
        <div class="w-6col media-right">
          ${image ? html`<aurora-img .data="${{ backgroundImage: true, ...image  }}"></aurora-img>` : nothing}
        </div>
      `;
    }
  }

  render() {
    if (!this.variant) {
      this.variant = 'default';
    }

    if (!this.background) {
      this.background = 'bg-light-grey';
    }

    return html` <section class="stacked-section-hero-block ${this.variant} ${this.background}">
      <div class="container">${this.getTemplate()}</div>
    </section>`;
  }
}
