// Created By: artur

import { html, LitElement, nothing, PropertyValues } from 'lit';
import { property, state } from 'lit/decorators.js';
import { map } from 'lit/directives/map.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { classMap } from 'lit/directives/class-map.js';

// styles
import styles from './aurora-img-css';
import global from '../../styles/global-css';
import typography from '../../styles/typography-css';

export type IImage = {
  contentType: 'Image' | 'Svg' | 'ResponsiveImage';
  imageSrc: string;
  altText: string;
  croppings?: Croppings;
  original?: OriginalImage;
};

type OriginalImage = Pick<IImage, 'contentType' | 'imageSrc' | 'altText'>;

type Croppings = [
  {
    imageSrc: string;
    srcSet: string;
    contentType: 'Image';
  },
];

export class AuroraImg extends LitElement {
  @property({ type: Object })
  data: IImage & {
    objectFit?: string;
    backgroundImage?: boolean;
    width?: number,
    height?: number,
  };

  @state()
  private _error: boolean;

  @state()
  private _loadedImage: string;

  static get styles() {
    return [global, typography, styles];
  }

  connectedCallback() {
    super.connectedCallback();
    this._initializeLoadedImage();
  }
  
  updated(changedProperties: PropertyValues) {
    super.updated(changedProperties);
    if (!changedProperties.has('data')) return;

    if (this?.data?.imageSrc !== this._loadedImage || this?.data?.original?.imageSrc !== this._loadedImage) {
      this._initializeLoadedImage();
    }
  }

  protected firstUpdated(): void {
    const { width, height } = this?.data || {};
    if (width) this.style.setProperty('--img-width', `${this?.data?.width}px`);
    if (height) this.style.setProperty('--img-height', `${this?.data?.height}px`);
  }

  private _initializeLoadedImage(): void {
    this._loadedImage = this?.data?.imageSrc || this?.data?.original?.imageSrc || '';
    if (this._loadedImage) {
      this._error = false;
      this._dispatchFallbackEvent(false);
    } else {
      this.handleFallbackImage();
    }
  }

  private handleFallbackImage(): void {
    if (this._error) return;
    this._error = true;
    // Dispatch event to parent component to handle the fallback image state if needed 
    this._dispatchFallbackEvent(true);
    this._loadedImage = this.fallbackImage;
  }

  private _dispatchFallbackEvent(hasError: boolean): void {
    this.dispatchEvent(new CustomEvent('fallbackImage', { detail: { hasError } }));
  }

  render() {
    if(!this.data || this._error) {
      return html`
        <div 
          class=${classMap({
            'aurora-img': true,
            'aurora-img--fallback': true,
          })}
          role='img'
          aria-label='fallback Image'
          title='fallback Image'
        >
          <img src="${this.fallbackImage}" alt='fallback Image'/>
        </div>
      `;
    }
    
    const {
      contentType = 'Image',
      imageSrc,
      altText,
      backgroundImage,
      objectFit,
      original,
      croppings
    } = this?.data || {};

    if (contentType.toUpperCase() === 'SVG') { // TODO: sanitize the SVG
      return html`
        <div class="aurora-img">
          ${unsafeHTML(imageSrc)}
        </div>
      `;
    }
    
    /* 
    * If background image we hide the img tag and 
    * use the background-image style with accessibility attributes
    */
    if (backgroundImage) {
      return html`
        <div 
          class=${classMap({
            'aurora-img': true,
            'aurora-img--fallback': this._error,
            'aurora-img--background': true,
          })}
          style="background-image: url(${this._loadedImage})"
          role='img'
          aria-label=${altText || 'Placeholder Image'}
          title=${altText || 'Placeholder Image'}
        >
          <p class="sr-only">This is a background image</p>
          <img src="${this._loadedImage}" alt="${altText || 'Placeholder Image'}" @error="${this.handleFallbackImage}" />
        </div>
      `;
    }

    /* 
    * If content type is responsive image we use the picture tag 
    * using the original and croppings to create the srcset for the image
    * else we use the normal img tag
    */

    return html`
      <div 
        class=${classMap({
          'aurora-img': true,
          'aurora-img--fallback': this._error,
        })}
      >
        ${contentType.toUpperCase() === 'RESPONSIVEIMAGE'
          ? html`
            <picture>
              ${map(croppings, (crop) => html`<source srcset="${crop.imageSrc}" media="${crop.srcSet}" />`)}
              <img 
                src="${this._loadedImage}" 
                alt="${altText || original?.altText || 'Placeholder Image'}"
                @error="${this.handleFallbackImage}"
                style="${objectFit && !this._error ? `object-fit: ${objectFit}` : nothing}"
              />
            </picture>`
          : html`
            <img 
              src="${this._loadedImage}" 
              alt="${altText || original?.altText || 'Placeholder Image'}"
              @error="${this.handleFallbackImage}"
              style="${objectFit && !this._error ? `object-fit: ${objectFit}` : nothing}"
            />
          `
        }
      </div>
    `;
  }

  get fallbackImage() {
    return 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjc0IiBoZWlnaHQ9IjE2OCIgdmlld0JveD0iMCAwIDI3NCAxNjgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xNjAuMjU3IDBIMTE4Ljg5OFY4OS45ODM0TDEyMS41NDUgMTA1Ljc1OEwxMjAuMjUyIDEwNi4wMUwxMTUuMzc1IDkyLjk3NUw2Ny44NDYyIDE4LjUxMzRDNTguNDkxNiAzLjE0NzA5IDU0LjY5MDEgMC4wMTk0MjY0IDM5LjM4NDQgMC4wMTk0MjY0SDUuODI3NEMyLjYyMjk2IC0xLjgwOTIzZS0wOCAwLjAxNTYyNSAyLjU0NDg3IDAuMDE1NjI1IDUuNjcyNTNWMTYyLjMyN0MwLjAxNTYyNSAxNjUuNDU1IDIuNjIyOTYgMTY3Ljk4MSA1LjgyNzQgMTY3Ljk4MUg0Ni4zOTA0Vjc1LjE4MDRMNDQuMjQwOCA2MS43NzYxTDQ1LjU5NDIgNjEuNDg0N0w0OS4zMzYxIDcxLjEwMDhMMTA0Ljg4NiAxNTIuNDJDMTEzLjg4MyAxNjYuNDY1IDExNy42ODQgMTY4IDEzMS4xOTggMTY4SDE2MC4yNzdDMTYzLjQ4MiAxNjggMTY2LjA4OSAxNjUuNDc1IDE2Ni4wODkgMTYyLjM0N1Y1LjY3MjUzQzE2Ni4wNjkgMi41NDQ4NyAxNjMuNDYyIDAgMTYwLjI1NyAwWiIgZmlsbD0id2hpdGUiLz4KPHBhdGggZD0iTTIxNi4yNDUgMFY1OC4wMjY4QzIxNi4yNDUgNjEuMTU0NSAyMTguODczIDYzLjY3OTkgMjIyLjA1NyA2My42Nzk5SDI2OC4xNzNDMjcxLjM3OCA2My42Nzk5IDI3My45ODUgNjEuMTU0NSAyNzMuOTg1IDU4LjAyNjhWMEgyMTYuMjQ1WiIgZmlsbD0id2hpdGUiLz4KPHBhdGggZD0iTTI2OC4xNTMgMTA2LjA2OUgyMjIuMDU3QzIxOC44NzMgMTA2LjA2OSAyMTYuMjQ1IDEwOC41OTQgMjE2LjI0NSAxMTEuNzQxVjE2OEgyNzMuOTY1VjExMS43NDFDMjczLjk2NSAxMDguNTk0IDI3MS4zNzggMTA2LjA2OSAyNjguMTUzIDEwNi4wNjlaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K';
  }
}
