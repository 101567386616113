import { LitElement, html } from 'lit';

// Styles
import styles from './container-css';

export class AuroraContainer extends LitElement {
  static get styles() {
    return [styles];
  }

  render() {
    return html` <section>
      <div class="container">
        <slot></slot>
      </div>
    </section>`;
  }
}
