import { html, LitElement, nothing } from 'lit';
import { property } from 'lit/decorators.js';
import('./desktop/desktopButtonTabset');
import('./mobile/mobileButtonTabset');
import debounce from '../../utils/debounce';

// styles
import styles from './aurora-button-tabset-css';
import typography from '../../styles/typography-css';
import global from '../../styles/global-css';

//types
import { ITabsetData } from '../../types/types';
import { getAttributeData } from '../../utils/getAttributeData';

export class AuroraButtonTabset extends LitElement {
  @property({ type: Object, attribute: false })
  data: ITabsetData;

  @property({ type: Boolean, state: true })
  _loading = false;

  @property({ type: Number, state: true, attribute: false })
   _pageWidth = window.innerWidth;
  
  private handleResize = () => {
    this._pageWidth = window.innerWidth;
    this.requestUpdate();
  };

  static get styles() {
    return [typography, styles, global];
  }

  async connectedCallback() {
    super.connectedCallback();
    this.data = getAttributeData.call(this, 'data');
    window.addEventListener('resize', debounce(this.handleResize, 200));
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('resize', this.handleResize);
  }

  render() {
    return html`
      <section class="button-tabset">
        <div class="container">
          <div class="w-full">
            ${this.data.title &&
            html`
              <div class="section-header">
                ${this.data.titleTag === 'H2' ? html`<h2>${this.data.title}</h2>` : nothing}
                ${this.data.titleTag === 'H3' ? html`<h3>${this.data.title}</h3>` : nothing}
                ${this.data.titleTag === 'H4' ? html`<h4>${this.data.title}</h4>` : nothing}
                ${this.data.subTitle &&
                html`
                  <div class="subtitle">
                    ${this.data.subTitleTag === 'H2'
                      ? html`<h2>${this.data.subTitle}</h2>`
                      : nothing}
                    ${this.data.subTitleTag === 'H3'
                      ? html`<h3>${this.data.subTitle}</h3>`
                      : nothing}
                    ${this.data.subTitleTag === 'H4'
                      ? html`<h4>${this.data.subTitle}</h4>`
                      : nothing}
                  </div>
                `}
              </div>
            `}
            ${this._pageWidth >= 1199
              ? html`<desktop-button-tabset
                  .desktopTabsetData="${this.data}"
                ></desktop-button-tabset>`
              : html`<mobile-button-tabset
                  .mobileTabsetData="${this.data}"
                ></mobile-button-tabset>`}
          </div>
        </div>
      </section>
    `;
  }
}
