// Created By: Mike Baker

// Lit
import { html, LitElement, nothing } from 'lit';
import { property } from 'lit/decorators.js';

// styles
import styles from './aurora-text-button-css.js';

// Types
import { IButton } from '../../types/types';
import { getAttributeData } from '../../utils/getAttributeData';

export class AuroraTextButton extends LitElement {
  @property({ type: Object, attribute: false })
  textdata: IButton;

  // Todo: remove this property later when Opti is done with
  // the variant removal in ProviderListBlockComponent
  @property({ type: String })
  variant?: 'default' | 'icon-left';

  static get styles() {
    return [styles];
  }

  connectedCallback() {
    super.connectedCallback();
    if (this.getRootNode() instanceof ShadowRoot) {
      return;
    }
    this.textdata = getAttributeData.call(this, 'textdata');
  }

  render() {
    const { url, title, buttonStyles, openInNewWindow, rel } = this.textdata;

    return html` 
      <a
        class="${['aurora-text-btn', this.variant, buttonStyles?.join(' ')].join(' ')}"
        target="${openInNewWindow ? '_blank' : '_self'}"
        href="${url}"
        rel=${rel}
      >
        ${this.textdata.icon?.imageSrc
          ? html`
            <img
              class="btn-icon"
              src="${this.textdata.icon.imageSrc}"
              alt="${this.textdata.icon.altText}"
            />`
          : nothing}
        ${title}
      </a>`;
  }
}
