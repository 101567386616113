// Created By: Mike Baker

import { html, LitElement, css } from 'lit';

export class AuroraGridLayout extends LitElement {
  static get styles() {
    return [
      css`
        :host {
          display: flex;
          flex-wrap: wrap;
          gap: var(--grid-layout-gap, 1rem);
          grid-template-columns: repeat(
            auto-fit,
            minmax(var(--grid-layout-min-size, 25rem), var(--grip-layout-max-size, 1fr))
          );
          padding-inline: 0.5rem;
        }
      `,
    ];
  }

  render() {
    return html` <slot></slot> `;
  }
}
