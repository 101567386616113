import { html, LitElement, nothing } from 'lit';
import { property, query, state } from 'lit/decorators.js';

// Styles
import styles from './aurora-vertical-tabset-css';
import global from '../../styles/global-css';
import typography from '../../styles/typography-css';
import { classMap } from 'lit/directives/class-map.js';

// Components
import '../../aurora-base-carousel/index';

// Interfaces
interface IPanel {
  buttonTitle: string;
  panelTitle?: string;
}
export class AuroraVerticalTabset extends LitElement {
  @state() private _activeTab = 1;
  @state() private _offset = 0;
  @state() private _isCarouselEnd = false;

  @property({ type: Object })
  data: {
    title?: string;
    description?: string;
    tabPanel: Array<IPanel>;
  };

  @query('#tab-buttons') private _tabsetButtons: HTMLElement;

  updateValues(e: CustomEvent) {
    this._offset = e.detail.offset;
    this._isCarouselEnd = e.detail.isCarouselEnd;
  }

  static get styles() {
    return [global, styles, typography];
  }

  private handleTabClick(index: number): void {
    const button = this._tabsetButtons.children[index] as HTMLElement;
    const buttonRect = button.getBoundingClientRect();
    const containerRect = this._tabsetButtons.getBoundingClientRect();
  
    // we check if the right edge of the button is outside the right edge of the container 
    // or if the left edge of the button is outside the left edge of the container.
    if (buttonRect.right > containerRect.right) {
      this._tabsetButtons.scrollLeft += buttonRect.right - containerRect.right;
    } else if (buttonRect.left < containerRect.left) {
      this._tabsetButtons.scrollLeft -= containerRect.left - buttonRect.left;
    }

    this._activeTab = index + 1;
  }

  private setActiveTabByURL(): void {
    const { hash: tabIdParam } = window.location;
    if (tabIdParam.length === 0) return;
    this._activeTab = this._activeTab + 1;
  }

  private updateURLHashByTab(): void {
    if (this.data.tabPanel.length) {
      const parsedButtonTitle = 'tab-' + this.data.tabPanel[this._activeTab-1].buttonTitle.replaceAll(/\s/g, '');
      window.location.hash = parsedButtonTitle;
    }
  }

  private handleTabKeyDown(e: KeyboardEvent): void {
    if (e.key === 'ArrowDown' && this._activeTab < this.data.tabPanel.length) {
      this._activeTab = this._activeTab + 1;
    }

    if (e.key === 'ArrowUp' && this._activeTab > 1) {
      this._activeTab = this._activeTab - 1;
    }
  }

  private isActiveTab(index: number): boolean {
    return this._activeTab === index + 1;
  }

  private setCarouselDirection(): 'horizontal' | 'vertical' {
    return window.innerWidth > 1199 ? 'vertical' : 'horizontal';
  }

  private handleResize = () => {
    this.requestUpdate();
  };

  protected firstUpdated(): void {
    this.setActiveTabByURL();
  }

  protected updated(): void {
    this.updateURLHashByTab();
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('resize', this.handleResize);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('resize', this.handleResize);
  }

  render() {
    const { title, description, tabPanel } = this.data;
    const carouselDirection = this.setCarouselDirection();

    return html`
      <section class="aurora-vertical-tabset">
        ${title ? html`
          <div class="aurora-vertical-tabset__header">
            <div class="aurora-vertical-tabset__header__title">
              <h2 class="title">${title}</h2>
            </div>
            ${description ? html`<p class="aurora-vertical-tabset__description">${description}</p>` : nothing}
          </div>
        ` : nothing}
        <div class="aurora-vertical-tabset__content">
          <div class=${classMap({
            "base-carousel-container left-shadow right-shadow": true,
            "left-shadow__active": this._offset > 0,
            "right-shadow__active": !this._isCarouselEnd,
          })}>
            <aurora-base-carousel
              .data=${{ direction: carouselDirection, gutters: false }}
              @sync-values="${this.updateValues}"
              id="tab-buttons"
              aria-label="Tab Carousel">
              ${tabPanel.map((panel, index) => html`
                <button
                  id="tab-${panel.buttonTitle.replaceAll(/\s/g, '')}"
                  type="button"
                  class="aurora-vertical-tabset__button ${this.isActiveTab(index) ? 'active' : ''}"
                  @click="${() => this.handleTabClick(index)}"
                  @keydown="${(e: KeyboardEvent) => this.handleTabKeyDown(e)}"
                  role="tab"
                  aria-selected="${this.isActiveTab(index) ? 'true' : 'false'}"
                  aria-controls="tabpanel-${panel.buttonTitle.replaceAll(/\s/g, '')}"
                  tabindex="${this.isActiveTab(index) ? '0' : '-1'}"
                  >
                  ${panel.buttonTitle}
                </button>
              `)}
            </aurora-base-carousel>
        </div>
            ${tabPanel.map((panel, index) => html`
            <div
              id="tabpanel-${panel.buttonTitle.replaceAll(/\s/g, '')}"
              class="aurora-vertical-tabset_panel ${this.isActiveTab(index) ? 'active' : ''}"
              data-id="${index + 1}"
              ?hidden="${!this.isActiveTab(index)}"
              role="tabpanel"
              aria-labelledby="tab-${panel.buttonTitle.replaceAll(/\s/g, '')}"
              aria-hidden="${!this.isActiveTab(index)}"
              >
                <slot name="${index + 1}"></slot>
            </div>
          `)}
        </div>
      </section>
    `;
  }
}