// Created By: Joe Kanakaraj

import { html, LitElement, nothing } from 'lit';
import { property, state } from 'lit/decorators.js';
import { domPurify } from '../../utils/domPurify';

// styles
import styles from './aurora-homepage-hero-css';
import global from '../../styles/global-css';
import { IButton } from '../../types/types';

export class AuroraHomepageHero extends LitElement {
  @property({ type: Object, attribute: false })
  data: {
    title: string;
    description: string;
    scroller: string[];
    cta?: IButton[];
  };
  @state() _loading: boolean;
  static get styles() {
    return [styles, global];
  }

  connectedCallback() {
    super.connectedCallback();

    const attr = this.getAttribute('data');
    if (attr !== null && !Object.keys(attr).includes('loaded')) {
      this.data = JSON.parse(this.getAttribute('data'));
      this.setAttribute('data', '{"loaded": true}');
    }
  }

  render() {
    const { title, description, scroller } = this.data;
    return html` <section class="homepage-hero">
      <div class="back-wave">
      </div>
      <div class="front-wave">
      </div>
      <div class="container">
        <div class="w-full">
          <div class="hero-container">
            <div class="title">
            <h1>${title}</h1>
              <div class="scroller">
              <span> 
                  ${scroller.map((item)=> {
                    return html `${item}<br />`
                  })}
                </span>
              </div>
            </div>
            <div class="body" .innerHTML=${domPurify(description)}></div>
            ${this.data.cta?.length > 0
              ? html`<div class="call-to-action">
                  ${this.data.cta?.map(
                    (btn) => html`<aurora-link-button .buttondata="${btn}"></aurora-link-button>`,
                  )}
                </div>`
              : nothing}
          </div>
        </div>
      </div> 
    </section>`;
  }
}
