// Created By: Mateo Trujillo
import { html, LitElement, nothing } from 'lit';
import { property } from 'lit/decorators.js';
import { map } from 'lit/directives/map.js';
import { classMap } from 'lit/directives/class-map.js';

// styles
import styles from './aurora-section-callout-block-css';
import global from '../../styles/global-css';

// Types & Interfaces
import { Image } from '../../types/types';
import { IButtons } from '../../types/buttons';

// Components
import '../../aurora-carousel/index';
import '../../aurora-tile/index';

interface ITile {
  title: string;
  image: Image;
  href?: string;
}

export class AuroraSectionCalloutBlock extends LitElement {
  @property({ type: Object })
  data: {
    title: string;
    description: string;
    useH1Title?: boolean;
    orientation?: 'vertical' | 'horizontal';
    background?: 'white' | 'gray' | 'gradient';
    button: IButtons;
    tiles?: ITile[];
    tilesLeft?: boolean;
  };

  private setBodyAlignment(): 'left' | 'center' {
    return this.data.orientation === 'vertical' || window.innerWidth <= 1199 ? 'center' : 'left';
  }

  private shouldRenderCTA(tiles: ITile[]): boolean {
    return !tiles.some(tile => tile.href);
  }

  private handleResize = () => {
    this.requestUpdate();
  };

  static get styles() {
    return [styles, global];
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('resize', this.handleResize);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('resize', this.handleResize);
  }

  render() {
    const { title, description, button, tiles, orientation = 'horizontal', useH1Title, background, tilesLeft } = this.data;
    const bodyAlignment = this.setBodyAlignment();
    const renderCTA = this.shouldRenderCTA(tiles);
    const tileCount = tiles?.length;

    return html`
      <section class=${classMap({
        'aurora-section-callout': true,
        [orientation]: true,
        [background]: true,
        'tiles-left': tilesLeft,
      })}>
        <div class="aurora-section-callout_body ${orientation}">
          <aurora-text-block .data="${{
            title,
            description,
            hasSeperator: true,
            alignment: bodyAlignment,
            hasPadding: false,
            useH1Title,
          }}"></aurora-text-block>
          ${orientation === 'horizontal' && renderCTA ? html`
            <div class="aurora-section-callout_cta">
              <aurora-link-button .buttondata=${{
                  ...button,
                  buttonStyles: [...(button.buttonStyles ?? []), 'full-width-button']
                }}>
              </aurora-link-button>
            </div>
          ` : nothing}
        </div>

        <div class="aurora-section-callout_grid-container__mobile">
          <aurora-carousel>
            ${map(
              tiles,
              (tile, idx) => html`
                <aurora-tile
                  class="aurora-section-callout_grid-container__tile" 
                  tabindex="${idx}"
                  .data=${tile}
                ></aurora-tile>
              `
            )}
          </aurora-carousel>
        </div>

        <div class=${classMap({
          'aurora-section-callout_grid-container': true,
          'three-tiles': tileCount === 3,
          'four-tiles': tileCount === 4,
          'five-tiles-vertical': tileCount === 5 && orientation === 'vertical',
          'five-or-more-tiles': tileCount >= 6,
        })}>
          ${map(
            tiles,
            (tile) => html`
              <aurora-tile class="aurora-section-callout_grid-container__tile" .data=${tile}></aurora-tile>
            `
          )}
        </div>

        ${orientation === 'vertical' && renderCTA ? html`
          <div class="aurora-section-callout_cta">
            <aurora-link-button .buttondata=${{
                ...button,
                buttonStyles: [...(button.buttonStyles ?? []), 'full-width-button']
              }}>
            </aurora-link-button>
          </div>
        ` : nothing}
      </section>
    `;
  }
}