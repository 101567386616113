import { html, LitElement, nothing } from 'lit';
import { property, query } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';

// styles
import styles from './aurora-dialog-css';
import global from '../../styles/global-css';
import typography from '../../styles/typography-css';

import '../../aurora-link-button/index';

export class AuroraDialog extends LitElement {
  private _open = false;

  @query('dialog')
  private dialogEl?: HTMLDialogElement;

  @property({ type: Object })
  data: {
    open: boolean,
    title: string,
    description: string,
    'no-backdrop'?: boolean,
    closeBtn?: boolean,
    usePrimary?: boolean,
    firstBtnTitle: string,
    firstBtnHref?: string,
    secondBtnTitle: string,
    secondBtnHref?: string,
  }

  static styles = [styles, global, typography];

  checkOpen() {
    this._open = this.data.open;
  }

  syncDialogStatus() {
    if (this._open) {
      this.dialogEl?.showModal();
      this.dispatchEvent(new CustomEvent('open', { bubbles: true, composed: true }));
    } else {
      this.dialogEl?.close();
      this.dispatchEvent(new CustomEvent('close', { bubbles: true, composed: true }));
    }
  }

  secondBtnClick(evt: Event) {
    if (!this.data.secondBtnHref) {
      evt.stopPropagation();
      evt.preventDefault();
    }
    this.dispatchEvent(new CustomEvent('secondBtnClick', evt));
  }

  firstBtnClick(evt: Event) {
    if (!this.data.firstBtnHref) {
      evt.stopPropagation();
      evt.preventDefault();
    }
    this.dispatchEvent(new CustomEvent('firstBtnClick', evt));
  }

  close() {
    this._open = false
    this.syncDialogStatus()
  }

  updated() {
    this.checkOpen();
    this.syncDialogStatus();
  }

  handleBackdropClick(event: MouseEvent) {
    if (event.target === this.dialogEl) {
      this.close();
    }
  }

  render() {
    const { title, description, closeBtn, usePrimary, firstBtnHref, firstBtnTitle, secondBtnHref, secondBtnTitle } = this.data;
    const noBackdrop = this.data['no-backdrop']
    const hasFirstBtn = firstBtnHref || firstBtnTitle
    const hasSecondBtn = secondBtnHref || secondBtnTitle
    const hasButtons = hasFirstBtn || hasSecondBtn
    return html`
      <dialog 
        class="${classMap({
          'aurora-dialog': true,
          'no-backdrop': noBackdrop
        })}" 
        @click="${this.handleBackdropClick}">
        <div class="aurora-dialog-container">

          ${closeBtn ? html`
            <div class="aurora-dialog-close-btn-container">
              <div class="aurora-dialog-close-btn" @click="${this.close}">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="24" height="24" fill="white"/>
                  <path d="M21.497 2.50302C20.9336 1.93964 19.9678 1.93964 19.3239 2.50302L11.9195 9.90744L4.59557 2.42254C4.03219 1.85915 3.0664 1.85915 2.42254 2.42254C1.85915 2.98592 1.85915 3.95171 2.42254 4.59557L9.82696 12L2.42254 19.4044C1.85915 19.9678 1.85915 20.9336 2.42254 21.5775C2.98592 22.1408 3.95171 22.1408 4.59557 21.5775L12 14.173L19.4044 21.5775C19.9678 22.1408 20.9336 22.1408 21.5775 21.5775C22.1408 21.0141 22.1408 20.0483 21.5775 19.4044L14.0926 12L21.497 4.59557C22.0604 4.03219 22.0604 3.0664 21.497 2.50302Z" fill="#512D6D"/>
                </svg>
              </div>
            </div>
          ` : nothing}
        
          <div class="aurora-dialog-content">
            <div class="aurora-dialog-title">
              ${title}
            </div>
            <div class="aurora-dialog-description">
              ${description}
            </div>

            ${hasButtons ? html`
              <div class="aurora-dialog-btns">
                ${hasSecondBtn ? html`
                  <aurora-link-button
                    .buttondata='${{
                        title: secondBtnTitle,
                        url: secondBtnHref ?? null,
                        autoWidth: true,
                        buttonStyles: ['secondary', 'full-width-button']
                      }}'
                    class="aurora-dialog-btns-second"
                    @click="${this.secondBtnClick}"
                  />
                ` : nothing}

                  ${hasFirstBtn ? html`
                    <aurora-link-button
                      .buttondata='${{
                        title: firstBtnTitle,
                        url: firstBtnHref ?? null,
                        buttonStyles: usePrimary ? ['primary', 'full-width-button'] : ['secondary', 'full-width-button'],
                        autoWidth: true
                      }}'
                      class="aurora-dialog-btns-first"
                      @click="${this.firstBtnClick}"
                    />
                  ` : nothing}
              </div>
          ` : nothing}
          </div>
        </div>
      </dialog>`;
  }
}
