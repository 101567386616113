// Created By: Joe Kanakaraj

import { html, LitElement } from 'lit';
import { property, state } from 'lit/decorators.js';
import { Image } from '../../types/types';

// styles
import styles from './aurora-callout-css';
import global from '../../styles/global-css';

export class AuroraCallout extends LitElement {
  @property({ type: Object, attribute: false })
  data: {
    title: string;
    calloutStyles?: string[];
    image: Image;
  };
  @state() _loading: boolean;
  static get styles() {
    return [styles, global];
  }

  connectedCallback() {
    super.connectedCallback();

    const attr = this.getAttribute('data');
    if (attr !== null && !Object.keys(attr).includes('loaded')) {
      this.data = JSON.parse(this.getAttribute('data'));
      this.setAttribute('data', '{"loaded": true}');
    }
  }

  render() {
    const { title, calloutStyles, image } = this.data;
    const { imageSrc, altText } = image;
    const style = calloutStyles[0];
    return html` <section class="callout">
      <div class="callout-block ${style}">
        <div class="img-wrapper">
          <img src="${imageSrc}" alt="${altText}" />
        </div>
        <span class="title" data-testid="callout-title"> ${title} </span>
      </div>
    </section>`;
  }
}
