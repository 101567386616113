// Created By: Mateo Trujillo

import { html, LitElement, nothing } from 'lit';
import { property, query, state } from 'lit/decorators.js';
import { domPurify } from '../../utils/domPurify';
import { map } from 'lit/directives/map.js';

// styles
import styles from './aurora-alert-block-css';
import global from '../../styles/global-css';
import textButtonStyle from '../../aurora-text-button/src/aurora-text-button-css';

// types
import { IButton } from '../../types/types';

export class AuroraAlertBlock extends LitElement {
  @property({ type: Object, attribute: false })
  data: {
    title: string;
    description: string;
    buttons?: IButton[];
    value: string,
    expires: string,
  };

  @query('#alert-ribbon') private _alertRibbon: HTMLElement;

  @property({ type: String })
  variant: 'information-alert' | 'urgent-alert' | 'important-alert';

  @state() _loading: boolean;

  @state() _expired = false;
  
  static get styles() {
    return [styles, global, textButtonStyle];
  }

  connectedCallback() {
    super.connectedCallback();

    const attr = this.getAttribute('data');
    if (attr !== null && !Object.keys(attr).includes('loaded')) {
      this.data = JSON.parse(this.getAttribute('data'));
      this.setAttribute('data', '{"loaded": true}');
    }
  }

  private setCookie(id: string, expDays: number) {
    const date = new Date();
    date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
    const pad = (num: number) => String(num).padStart(2, '0');
    const dateString = `${date.getUTCFullYear()}-${pad(date.getUTCMonth() + 1)}-${pad(date.getUTCDate())}T${pad(date.getUTCHours())}:${pad(date.getUTCMinutes())}:${pad(date.getUTCSeconds())}.000Z`;
    const expires = `expires=${date.toUTCString()}`;
    document.cookie = `alert-${id}=${dateString}; ${expires}; path=/`;
  }

  private _dismissAlert() {
    const alert = this._alertRibbon;
    const cValue = alert.dataset.value;
    const cExpires = alert.dataset.expires;

    alert.addEventListener('click', () => {
      alert.classList.add('dismiss');
    });
    this.setCookie(cValue, parseInt(cExpires, 10));
  }

  protected firstUpdated(): void {
    // check if cookie is expired
    const cookieName = `alert-${this.data.value}`;
    const cookieMatch = document.cookie.split('; ').find(row => row.startsWith(cookieName));

    if (!cookieMatch) {
      this._expired = false;
      return;
    }

    const cookieValue = cookieMatch.split('=')[1];
    const expiryDate = new Date(cookieValue);
    if (expiryDate < new Date()) {
      this._expired = true;
    } else {
      this._expired = false;
    }
  }

  render() {
    if (this._expired) return nothing;

    const { title, description, buttons, value, expires } = this.data;

    return html` <section
      id="alert-ribbon"
      class="alert-block score-alert-dismissible ${this.variant}"
      data-value=${value}
      data-expires=${expires}
    >
      <div class="container">
        <div class="wrapper">
          <button
            @click=${this._dismissAlert}
            type="button"
            aria-label="close"
            class="close"
          ></button>
          <div class="title">
            <span>${title}</span>
          </div>
          <div class="body-text" .innerHTML=${domPurify(description)}></div>
          ${buttons
            ? html` <div class="cta-area">
                ${buttons?.length > 0
                  ? html`
                      ${map(
                        buttons,
                        (btn: IButton) =>
                          html` <aurora-text-button .textdata="${{...btn, 
                            buttonStyles: [
                              this.variant === 'urgent-alert' ? 'over-dark' : '', 
                              this.variant === 'important-alert' ? 'text-urgent' : ''
                            ]}}"></aurora-text-button>`,
                      )}
                    `
                  : nothing}
              </div>`
            : nothing}
        </div>
      </div>
    </section>`;
  }
}
