// Created By: artur

import { html, LitElement } from 'lit';

// styles
import styles from './aurora-scroll-css';
import global from '../../styles/global-css';
import typography from '../../styles/typography-css';

export class AuroraScroll extends LitElement {
  static get styles() {
    return [styles, global, typography];
  }

  render() {
    return html`
      <section class="aurora-scroll">
        <slot></slot>
      </section>`
  }
}
