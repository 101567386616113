// Created By: Mike Baker

import { html, LitElement, nothing } from 'lit';
import { property } from 'lit/decorators.js';

// styles
import styles from './aurora-large-card-css';
import global from '../../styles/global-css';

// Types
import { Image } from '../../types/types';
import { IButtons } from '../../types/buttons';

import { getAttributeData } from '../../utils/getAttributeData';

export class AuroraLargeCard extends LitElement {
  @property({ type: Object, attribute: false })
  cardData: {
    title?: string;
    description?: string;
    image?: Image;
    button?: IButtons & { handleEvent?: boolean };
  };

  static get styles() {
    return [styles, global];
  }

  private handleCtaClick(event: Event) {
    if (!this.cardData.button?.handleEvent) return;
    event.preventDefault();
    event.stopPropagation();
    this.dispatchEvent(new CustomEvent('onCtaClick', event));
  }


  connectedCallback() {
    super.connectedCallback();
    if (this.getRootNode() instanceof ShadowRoot) {
      return;
    }
    this.cardData = getAttributeData.call(this, 'cardData');
  }

  render() {
    const { title, image, description, button } = this.cardData;

    return html` <div class="large-card">
      <aurora-img .data="${image}"></aurora-img>
      <div class="caption">
        <span class="card-header">${title}</span>
        <div class="card-body">
        ${description
        ? html`<p>${description}</p>`
        : nothing}
          </div>
        ${button
          ? html` <div class="call-to-action">
              <aurora-link-button 
                .buttondata="${{...button,
                  buttonStyles: [...(button.buttonStyles ?? []), 'full-width-button']}}" 
                @click=${this.handleCtaClick}>
              </aurora-link-button>
            </div>`
        : nothing}
      </div>
    </div>`;
  }
}
