// Created By: artur

import { html, LitElement, nothing, PropertyValues } from 'lit';
import { property, query } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';

// styles
import styles from './aurora-floating-cta-css';
import global from '../../styles/global-css';
import typography from '../../styles/typography-css';
import { Image } from '../../types/types';

export class AuroraFloatingCta extends LitElement {
  @property({ type: Boolean, attribute: false })
  _menuOpen = false;

  @property({ type: Object })
  data: {
    label: string;
    collapseAt: number;
    icon: Image;
    items: {
      label: string;
      url: string;
      openInNewWindow?: boolean;
    }[];
  };

  // add internaldata _collapsed
  @property({ type: Boolean, attribute: false })
  private _collapsed = false;

  @query('#aurora-floating-cta')
  private _floatingCtaElement: HTMLElement;

  static get styles() {
    return [styles, global, typography];
  }
  
  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('scroll', this.onScroll.bind(this));
    this.addEventListener('focusout', this.handleFocusOut);
  }
  
  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('scroll', this.onScroll.bind(this));
    this.removeEventListener('focusout', this.handleFocusOut);
  }

  protected updated(_changedProperties: PropertyValues): void {
    super.updated(_changedProperties);

    if (_changedProperties.has('data') && this.data?.collapseAt) {
      this._floatingCtaElement.style.setProperty('--collapsed-at', `${this.data.collapseAt}px`);
    }
  }

  private onScroll() {
    const { scrollY: offset, innerHeight } = window;
    const collapseAt = this.data?.collapseAt || 160;

    // 50% of the screen
    const triggerPosition = (innerHeight / 2) - collapseAt;
  
    // Check if it should be collapsed
    const shouldBeCollapsed = offset >= triggerPosition;
  
    // Update collapsed state if it changes
    if (this._collapsed !== shouldBeCollapsed) {
      this._collapsed = shouldBeCollapsed;
    }
  }
  
  private handleKeydown(event: KeyboardEvent) {
    if (event.key === 'Escape' && this._menuOpen) {
      this._menuOpen = false;
      this._floatingCtaElement.focus();
    }
  }

  private handleFocusOut(event: FocusEvent) {
    const newFocusTarget = event.relatedTarget as HTMLElement;
    if (!this.contains(newFocusTarget)) {
      this._menuOpen = false;
    }
  }

  private toggleMenu() {
    this._menuOpen = !this._menuOpen;
  }

  render() {
    const { label, items, icon } = this.data || {};

    if (!label || !items?.length) return nothing;

    return html`
      <section
        id="aurora-floating-cta"
        class="aurora-floating-cta"
        @keydown="${this.handleKeydown}"
      >
        <button
          aria-expanded="${this._menuOpen}"
          aria-controls="aurora-floating-cta-menu"
          class="${
            classMap({
              'aurora-floating-cta__button': true,
              'aurora-floating-cta__button--collapsed': this._collapsed,
              'aurora-floating-cta__button--hidden': this._menuOpen,
            })
          }"
          @click="${this.toggleMenu}"
        >
          <div class="aurora-floating-cta__label">
            ${label}
          </div>

          <div class="aurora-floating-cta__icon">
           ${icon ? html`<aurora-img .data="${{...icon, width: 20, height: 20 }}"></aurora-img>` : nothing} 
          </div>
        </button>
        
        <nav
          id="aurora-floating-cta-menu"
          tabindex="-1"
          class="${
            classMap({
              'aurora-floating-cta__menu': true,
              'aurora-floating-cta__menu--sticky': this._collapsed,
              'aurora-floating-cta__menu--open': this._menuOpen,
            })
          }"
        >
          <div class="aurora-floating-cta__menu-header">
            <div class="aurora-floating-cta__label">${label}</div>

            <button
              class="aurora-floating-cta__icon"
              @click="${this.toggleMenu}"
              tabindex="${this._menuOpen ? '0' : '-1'}"
              aria-label="Close menu"
              title="Close menu"
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="close">
                  <path id="Vector" d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="white"/>
                </g>
              </svg>
            </button>
          </div>
        
          <ul>
            ${items.map(item => html`
              <li class="aurora-floating-cta__menu-item">
                <a 
                  href="${item.url}"
                  target="${item.openInNewWindow ? '_blank' : '_self'}"
                  tabindex="${this._menuOpen ? '0' : '-1'}"
                  >
                  ${item.label}
                </a>
              </li>
            `)}
          </ul>
        </nav>
      </section>
    `
  }
}
