// Created By: Joe Kanakaraj

import { html, LitElement, nothing } from 'lit';
import { property } from 'lit/decorators.js';
import { map } from 'lit/directives/map.js';
import { tabIndex } from '../../utils/getTabIndex';

// styles
import styles from './aurora-mosaic-layout-css';
import global from '../../styles/global-css';

// Components
import '../../aurora-mosaic-tiles';
import { Image } from '../../types/types';
import '../../aurora-carousel/index';


interface ITile {
  title: string;
  href: string;
  gradient?: string;
  alignment?: 'center' | 'left' | 'right';
  position?: 'top' | 'middle' | 'bottom';
  background: Image;
  icon?: Image;
  textSize?: 'small' | 'large';
  hasOverlay?: boolean;
}

export class AuroraMosaicLayout extends LitElement {
  @property({ type: Object })
  data: {
    title: string,
    tiles: ITile[];
    tabindex: number;
  };

  static get styles() {
    return [styles, global];
  }

  render() {
    const { tiles, title } = this.data;

    return html`
      <nav class="mosaic-layout">
      ${title ? html `
        <h2 class="mosaic-layout__title"> ${title} </h2>`
      : nothing}
        <div class="mosaic-layout__carousel-container">
          <aurora-carousel>
            ${map(tiles, (tile: ITile, idx: number) => {
              const { alignment, position, textSize, hasOverlay, ...rest } = tile;
    
              return html`<div
                tabindex=${tabIndex(this.data?.tabindex)}
                class="mosaic-layout-item-mobile"
                role="tab"
              >
                <aurora-mosaic-tiles
                  alignment="${alignment}"
                  position="${position}"
                  textSize="${textSize}"
                  hasOverlay="${hasOverlay}"
                  .data=${rest}
                ></aurora-mosaic-tiles>
              </div>`;
            })}
          </aurora-carousel>
        </div>
        <ul class="mosaic-layout__grid">
          ${map(tiles, (tile: ITile, idx: number) => {
            const { alignment, position, textSize, hasOverlay, ...rest } = tile;

            return html`<li
              tabindex=${tabIndex(this.data?.tabindex)}
              class="mosaic-layout-item"
            >
              <aurora-mosaic-tiles
                alignment="${alignment}"
                position="${position}"
                textSize="${textSize}"
                hasOverlay="${hasOverlay}"
                data=${JSON.stringify(rest)}
              ></aurora-mosaic-tiles>
            </li>`;
          })}
        </ul>
      </nav>
    `;
  }
}
