// Created By: Jackeline Mendoza

import { html, LitElement, nothing } from 'lit';
import { property, state } from 'lit/decorators.js';
// styles
import styles from './aurora-simple-card-css';
import global from '../../styles/global-css';
// types
// import { getAttributeData } from '../../utils/getAttributeData';
import { IButton } from '../../types/types';
export class AuroraSimpleCard extends LitElement {
  @property({ type: Object, attribute: false })
  data: {
    title?: string;
    description?: string;
    button: Omit<IButton, 'buttonStyles'>;
  };
  @state() _loading: boolean;
  static get styles() {
    return [styles, global];
  }
  connectedCallback() {
    super.connectedCallback();
    const attr = this.getAttribute('data');

    if (attr !== null && !Object.keys(attr).includes('loaded')) {

      this.data = JSON.parse(this.getAttribute('data'));

      this.setAttribute('data', '{"loaded": true}');
    }
  }
  render() {
    const { title, description, button } = this.data;
    return html`
      <div class="simple-card">
        <div class="caption">
          <h5 class="card-header">${title}</h5>
          <div class="card-body">
           ${description
        ? html`<p>${description}</p>`
        : nothing}
            </div>
          ${button ? html`
              <div class="call-to-action">
                <aurora-text-button .textdata="${button}"></aurora-text-button>
              </div>
              ` : nothing}
        </div>
    </div>`;
  }
}
