import { LitElement, html, nothing } from 'lit';
import { property } from 'lit/decorators.js';
import { map } from 'lit/directives/map.js';
import { domPurify } from '../../utils/domPurify';

// Components
import '../../aurora-link-button';

// Styles
import styles from './aurora-interior-hero-css';
import global from '../../styles/global-css';

// Types
import { Image, IButton } from '../../types/types';

export class AuroraInteriorHero extends LitElement {
  @property({ type: Object, attribute: false })
  herodata: {
    title: string;
    description?: string;
    buttons: IButton[];
    image: Image;
  };

  static get styles() {
    return [styles, global];
  }

  async connectedCallback() {
    super.connectedCallback();

    const attr = this.getAttribute('herodata');
    if (attr !== null && !Object.keys(attr).includes('loaded')) {
      this.herodata = JSON.parse(this.getAttribute('herodata'));
      this.setAttribute('herodata', '{"loaded": true}');
    }
  }

  HeroTemplate = () => {
    return html`${this.herodata.title && this.herodata.buttons.length
      ? html`<div class="interior-page-hero">
          <h1>${this.herodata.title}</h1>
          ${this.herodata.description
            ? html` <div class="interior-page-description"
                .innerHTML=${domPurify(this.herodata.description)}>
              </div>`
            : nothing}
          <div class="interior-page-button-well">
            ${this.herodata.buttons?.length > 0
              ? html`<div class="cta">
                  ${this.herodata.buttons?.map(
                    (btn) => html`<aurora-link-button .buttondata="${btn}"></aurora-link-button>`,
                  )}
                </div>`
              : nothing}
          </div>
        </div>`
      : nothing}`;
  };

  render() {
    const { image } = this.herodata;

    return html` <section class="interior-page">
      <div class="container">
        <div class="w-full">
          <div class="interior-page-image-wrapper">
            <picture>
              ${image?.croppings?.length > 0
                ? map(
                    image?.croppings,
                    (crop) => html`<source srcset="${crop.imageSrc}" media="${crop.srcSet}" />`,
                  )
                : nothing}
              <img src="${image?.original?.imageSrc}" alt="${image?.original?.altText}" />
            </picture>
          </div>
          ${html`${this.HeroTemplate()}`}
        </div>
      </div>
    </section>`;
  }
}
