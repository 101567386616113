// Created By: Mateo Trujillo

import { html, LitElement } from 'lit';

// styles
import styles from './aurora-reveal-card-layout-css';
import global from '../../styles/global-css';
import { property } from 'lit/decorators.js';

export class AuroraRevealCardLayout extends LitElement {
  @property({ type: String })
  title: string;
  
  static get styles() {
    return [styles, global];
  }

  render() {
    return html`
      <div class="reveal-card-layout">
        <h2 class="reveal-card-layout__title"> ${this.title} </h2>
        <div class="reveal-card-layout__list">
          <slot></slot>
        </div>
      </div>
    `;
  }
}
