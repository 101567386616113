// Created By: Mateo Trujillo
import { html, LitElement } from 'lit';
import { property } from 'lit/decorators.js';
import styles from './aurora-alert-ribbon-css';
import global from '../../styles/global-css';
import { domPurify } from '../../utils/domPurify';
export class AuroraAlertRibbon extends LitElement {
  @property({ type: Object })
  data: { description: string };

  @property({ type: String })
  variant: 'information' | 'urgent' = 'information';

  // Include styles for your component
  static get styles() {
    return [styles, global];
  }

  connectedCallback() {
    super.connectedCallback();
  }

  private setCookie(id: string, expDays: number) {
    const date = new Date();
    date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
    const dateString = `${date.getUTCFullYear()}-${
      date.getUTCMonth() + 1
    }-${date.getUTCDate()}T${date.getUTCHours()}:${date.getUTCMinutes()}:${date.getUTCSeconds()}.000Z`;
    const expires = `expires=${date.toUTCString()}`;
    document.cookie = `alert-${id}=${dateString}; ${expires}; path=/`;
  }

  private _dismissAlert() {
    const alert = this.renderRoot.querySelector('.score-alert-dismissible') as HTMLButtonElement;
    const cValue = alert.dataset.value;
    const cExpires = alert.dataset.expires;

    alert.addEventListener('click', () => {
      alert.classList.add('dismiss');
    });
    this.setCookie(cValue, parseInt(cExpires, 10));
  }

  render() {
    const { description } = this.data;

    return html`
      <section class="global-alert-ribbon score-alert-dismissible ${this.variant}">
        <div class="container">
          <div class="ribbon-wrapper">
            <div class="body" .innerHTML=${domPurify(description)}></div>
            <div class="alert-close">
              <button
                @click=${this._dismissAlert}
                type="button"
                aria-label="close"
                class="close"
              ></button>
            </div>
          </div>
        </div>
      </section>
    `;
  }
}
