import { LitElement, html, nothing } from 'lit';
import { property } from 'lit/decorators.js';
import { map } from 'lit/directives/map.js';

// Components
import '../../aurora-link-button';

// Styles
import styles from './aurora-primary-hero-css';
import global from '../../styles/global-css';

// Types
import { Image, IButton } from '../../types/types';

export class AuroraHero extends LitElement {
  @property({ type: Object, attribute: false })
  herodata: {
    title: string;
    description: string;
    buttons?: IButton[];
    image: Image;
  };

  static get styles() {
    return [styles, global];
  }

  connectedCallback() {
    super.connectedCallback();

    const attr = this.getAttribute('herodata');
    if (attr !== null && !Object.keys(attr).includes('loaded')) {
      this.herodata = JSON.parse(this.getAttribute('herodata'));
      this.setAttribute('herodata', '{"loaded": true}');
    }
  }

  render() {
    const { image, title, description, buttons } = this.herodata;

    return html` <section class="primary-hero-block">
      <div class="container">
        <div class="w-full">
          <div class="image-wrapper">
            <picture>
              ${image?.croppings?.length > 0
                ? map(
                    image?.croppings,
                    (crop) => html` <source srcset="${crop.imageSrc}" media="${crop.srcSet}" />`,
                  )
                : nothing}
              <img src="${image?.original?.imageSrc}" alt="${image?.original?.altText}" />
            </picture>
          </div>
          <div class="hero">
            <h1>${title}</h1>
            <div class="hero-body">
              <p>${description}</p>
            </div>
            <div class="call-to-action">
              ${buttons?.length > 0
                ? html`
                    ${buttons?.map(
                      (btn) => html` <aurora-link-button
                        .buttondata="${{
                          ...btn,
                          buttonStyles: [...(btn?.buttonStyles ?? []), 'full-width-button']
                        }}"
                      ></aurora-link-button>`,
                    )}
                    `
                : nothing}
            </div>
          </div>
        </div>
      </div>
    </section>`;
  }
}
