// Created By: Victor Tommasi

import { html, LitElement, nothing } from 'lit';
import { property } from 'lit/decorators.js';

// styles
import styles from './aurora-location-card-css';
import global from '../../styles/global-css';
import { IButton, Image } from '../../types/types';

export class AuroraLocationCard extends LitElement {
  @property({ type: Object })
  data: {
    title: string,
    category: string,
    button: IButton,
    image: Image,
  }

  static get styles() {
    return [styles, global];
  }

  render() {
    const { title, category, button: buttonData, image } = this.data;

    return html`
      <article class="location-card">
        <div class="image-wrapper">
          <picture>
            ${image?.croppings?.length > 0
              ? image?.croppings.map((crop) => html` <source srcset="${crop.imageSrc}" media="${crop.srcSet}" />`)
              : nothing
            }
            <img src="${image.original?.imageSrc}" alt="${image.original?.altText}" />
          </picture>
        </div>
        <h5>${category}</h5>
        <h4>${title}</h4>
        <a href="${buttonData.url}" aria-label="View ${title}" target="${buttonData.openInNewWindow ? '_blank' : '_self'}"></a>
        <div class="card-footer">
          <aurora-text-button .textdata='${buttonData}' />
        </div>
      </article>`
  }

}
