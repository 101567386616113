// Created By: Mateo Trujillo

import { html, LitElement, nothing } from 'lit';
import { property, state } from 'lit/decorators.js';
import { map } from 'lit/directives/map.js';

// styles
import styles from './aurora-status-page-css';
import globalCss from '../../styles/global-css';

// Types
import { IButtons } from '../../types/buttons';

export class AuroraStatusPage extends LitElement {
  @property({ type: Object, attribute: false })
  errordata: {
    title: string;
    subtitle: string;
    description: string;
    buttons: IButtons[];
  };

  static get styles() {
    return [globalCss, styles];
  }

  @state() _loading: boolean;

  connectedCallback() {
    super.connectedCallback();
    const attr = this.getAttribute('errordata');
    if (attr !== null && !Object.keys(attr).includes('loaded')) {
      this.errordata = JSON.parse(this.getAttribute('errordata'));
      this.setAttribute('errordata', '{"loaded": true}');
    }
  }

  render() {
    const { title, subtitle, description, buttons } = this.errordata;

    return this._loading
      ? html` <section class="aurora-status"></section>`
      : html` <section class="aurora-status">
          <div class="background-assets"></div>
          <div class="container">
            <div class="w-full">
              <div class="content-area">
                <h1>${title}</h1>
                <h2>${subtitle}</h2>
                <h3 class="description">${description}</h3>
                ${buttons.length > 0
                  ? html`<div class="cta">
                      ${map(
                        buttons,
                        (btn: IButtons) =>
                          html`<aurora-link-button 
                            .buttondata="${{
                              ...btn,
                              buttonStyles: [...(btn.buttonStyles ?? []), 'full-width-button']}}"
                            }}">
                          </aurora-link-button>`,
                      )}
                    </div>`
                  : nothing}
              </div>
            </div>
          </div>
        </section>`;
  }
}
