import { LitElement, html, css} from 'lit';
import { property } from 'lit/decorators.js';
import { data } from '../header';
import { footerData } from '../footerData';

// styles
import global from '../../styles/global-css';
export class AuroraLayout extends LitElement {
  @property({ type: Object })
  data = data;

  @property({ type: Object })
  footerdata = footerData;

  static get styles() {
    return [css`
      :host {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
      }

      ::sloted(*) {
        max-width: 1536px;
      }
    `, global];
  }

  render() {
    return html`
      <aurora-header headerdata="${JSON.stringify(this.data)}"></aurora-header>
        <main>
          <slot></slot>
        </main>
      <aurora-footer footerdata="${JSON.stringify(this.footerdata)}"></aurora-footer>
    `;
  }
}
