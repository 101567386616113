// Created By: Mateo Trujillo
import { html, LitElement } from 'lit';
import { property } from 'lit/decorators.js';

// components
import '../../aurora-tab-panel-horizontal/auroraHorizontalTabPanel';
import '../../aurora-tab-panel-vertical/auroraVerticalTabPanel';
import '../../aurora-link-button/index';

// types
import { Image } from '../../types/types';
import { IButtons } from '../../types/buttons';
export interface ITabPanelData {
  title: string;
  description: string;
  image?: Image;
  buttons?: IButtons[]; 
  imagePosition?: 'top' | 'right' | 'left';
  variant?: 'vertical' | 'horizontal';
}

export class AuroraTabPanel extends LitElement {
  @property({ type: Object })
  data: ITabPanelData;

  render() {
    return html`
      ${this.data.variant === 'vertical' ? html`
          <aurora-vertical-tab-panel .data=${this.data}></aurora-vertical-tab-panel>
        ` : html`
          <aurora-horizontal-tab-panel .data=${this.data}></aurora-horizontal-tab-panel>
        `
      }
    `
  }
}
