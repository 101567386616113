// Created By: artur

import { html, LitElement, nothing } from 'lit';
import { property, state } from 'lit/decorators.js';

// styles
import styles from './aurora-hero-css';
import global from '../../styles/global-css';
import typography from '../../styles/typography-css';
import { classMap } from 'lit/directives/class-map.js';

import '../../aurora-img/index';
import '../../aurora-link-button/index';
import { IImage } from '../../aurora-img/src/auroraImg';
import { IButtons } from '../../types/buttons';

  export class AuroraHero extends LitElement {
    @property({ type: Object })
    herodata: {
      title: string;
      description: string;
      image?: IImage;
      buttons?: IButtons[];
      hasPadding?: Boolean; //This prop will add optional bottom padding for the use cases mentioned in ZH Aurora Hero Component
    }
    
    @property({ type: Boolean, attribute: 'hide' })
    _hide = false;

    @state()
    private _imageFallback: boolean;

    static get styles() {
      return [styles, global, typography];
    }

    connectedCallback() {
      super.connectedCallback();
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has('hide-aurora-hero')) {
        this._hide = true;
      }
    }

    disconnectedCallback() {
      super.disconnectedCallback();
    }

    get hasImage() {
      const keys = Object.keys(this.herodata?.image ?? {});
      const res = keys.filter(key => key!=='alt').length;
      return res > 0;
    }

    handleFallbackImage(e: CustomEvent) {
      const { detail: { hasError } } = e;
      this._imageFallback = hasError;
    }

    render() {
      if (this._hide) {
        return nothing;
      }
  
      if (!this.herodata) return html``
      const { title, description, image, buttons, hasPadding } = this.herodata;

      return html`
        <div 
          class="${classMap({ 
            'aurora-hero': true,
            'image-mode': this.hasImage,
            'optional-padding':  hasPadding ? true : false,
          })}"
        >
          <div class="hero-container" >
            ${this.hasImage ? html`
              <div class=${classMap({
                  'hero-image': true,
                  'hero-image--fallback': this._imageFallback,
                })}>
                <aurora-img .data=${image} @fallbackImage=${this.handleFallbackImage}></aurora-img>
              </div>`
            : nothing}
            
            <div class="hero-content">
              <aurora-text-block .data="${{
                title: title,
                description: description,
                primaryButton: buttons?.[0],
                secondaryButton: buttons?.[1],
              }}"></aurora-text-block>
            </div>

            <div class="hero-search-slot">
              <slot name="search"></slot>
            </div>
          </div>
        </div>
      `;
    }
  }
