import { LitElement, html, nothing } from 'lit';
import { property } from 'lit/decorators.js';

// styles
import styles from './aurora-section-highlight-css';
import global from '../../styles/global-css';
import typography from '../../styles/typography-css';

// types
import { IButton, Image } from '../../types/types';

// components
import '../../aurora-text-block/index';
import '../../aurora-img/index';

export class AuroraSectionHighlight extends LitElement {
  @property({ type: Object })
  data: {
    body: {
      title: string;
      description: string;
      buttons?: IButton[];
      hasSeperator?: boolean;
      variant?: 'gradient' | 'grey' ;
      alignment?: 'left' | 'center';
      hasPadding?: boolean;
    };
    image: Image;
    variant?: 'centered' | 'overlay' | 'offset';
  };

  static get styles() {
    return [styles, global, typography];
  }

  connectedCallback() {
    super.connectedCallback();
  }

  render() {
    if (!this.data.variant) {
      this.data.variant = 'centered';
    }

    if(this.data.variant == 'centered') {
      this.data.body.alignment = 'center';
      this.data.body.variant = 'grey';
    }else {
      this.data.body.alignment = 'left';
      this.data.body.variant = 'gradient';
    }

    const { image, variant, body } = this.data;

    return html`
      <section class="section-highlight ${variant}">
        <div class="section-highlight-image">
          ${image ? html`<aurora-img .data="${{objectFit: 'cover', ...image}}"></aurora-img>` : nothing}
        </div>
        <div class="section-highlight-hero">
          <aurora-text-block .data="${{
            title: body.title,
            variant: body.variant,
            description: body.description,
            hasSeperator: true,
            alignment: body.alignment,
            hasPadding: true,
            primaryButton: body.buttons?.[0],
            secondaryButton: body.buttons?.[1],
          }}"></aurora-text-block>
        </div>
      </section>
    `;
  }
}