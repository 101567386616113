// Created By: Victor Tommasi

import { LitElement, html, nothing } from 'lit';
import { property, query } from 'lit/decorators.js';
import { IButton } from '../../types/types';

// styles
import styles from './aurora-carousel-css';
import global from '../../styles/global-css';
import typography from '../../styles/typography-css';

type AuroraCarouselProps = {
  title?: string,
  button?: IButton
}

export class AuroraCarousel extends LitElement {
  @property({ type: Object }) data?: AuroraCarouselProps = {};
	@property({ type: Number }) private _currentIndex = 0;
  @property({ type: Number }) private _offset = 0;

  @query('#base-carousel') private _baseCarousel!: HTMLElement;

  connectedCallback() {
    super.connectedCallback();
  }

  static get styles() {
    return [styles, global, typography];
  }

  get numberOfSlides() {
    return this.children.length;
  }

  get slideWidth() {
    return this.children[0]?.clientWidth;
  }

  get itemMargin() {
    const styles = getComputedStyle(this);
    return parseFloat(styles.getPropertyValue('--item-margin'));
  }

  get itemTotalWidth() {
    return this.slideWidth + this.itemMargin;
  }

  get maxIndex() {
    const slidePerPage = Math.floor(this._baseCarousel?.clientWidth / this.itemTotalWidth) || 1;
    return (this.numberOfSlides - slidePerPage);
  }

  private move(direction: 'left' | 'right') {
    if (direction === 'left') {
      this._baseCarousel.dispatchEvent(new CustomEvent('movePrevious'));
    } else {
      this._baseCarousel.dispatchEvent(new CustomEvent('moveNext'));
    }
  }

  private handlePageChange(e: CustomEvent): void {
    this._currentIndex = e.detail.currentIndex;
    this._baseCarousel.dispatchEvent(new CustomEvent('updateCarousel', {
      detail: { newIndex: this._currentIndex },
    }));
  }

  private syncChildValues(e: CustomEvent) {
    const { currentIndex, offset } = e.detail;
    this._currentIndex = currentIndex;
    this._offset = offset;
  }

  render() {
    return html`
      <section class="aurora-carousel"> 
        <div class="carousel-header">
          ${this.data?.title 
            ? html`<h2>${this.data.title}</h2>`
            : nothing}
            <div class="carousel-link mobile-hidden">
            ${this.data?.button 
              ? html` <aurora-text-button .textdata=${this.data.button} />`
              : nothing}
            </div> 
        </div>
        <p class="sr-only">Carousel with multiple slides. Use the Previous and Next buttons to navigate.</p>
        <aurora-base-carousel
          id="base-carousel"
          @sync-values=${this.syncChildValues}>
          <slot @slotchange=${() => this.requestUpdate()}></slot>
        </aurora-base-carousel>
        <aurora-carousel-pagination
          .data=${{
            currentIndex: this._currentIndex,
            numberOfElements: this.numberOfSlides,
            lastIndex: this.maxIndex,
            showOnDesktop: false,
            visible: true
          }}
          @page-change=${this.handlePageChange}
        ></aurora-carousel-pagination>
        <div class="carousel-control">
          <button class="button-prev"
            aria-label="previous"
            @click=${() => this.move('left')}
            ?disabled=${this._offset <= 0}>
          </button>
          <button class="button-next" 
            aria-label="next"
            @click=${() => this.move('right')}
            ?disabled=${this._currentIndex === this.maxIndex}>
          </button>
        </div>
        <div class="carousel-link desktop-hidden">
          ${this.data?.button 
            ? html` <aurora-text-button .textdata=${this.data.button} />`
            : nothing}
        </div> 
		  </section>
    `;
  }
}