// Created By: Joe Kanakaraj
import { html, LitElement, nothing } from 'lit';
import { property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';

// components
import '../../aurora-text-block/index';
import '../../aurora-img/index';

// styles
import styles from './aurora-section-hero-css';
import global from '../../styles/global-css';
import typography from '../../styles/typography-css';

//types
import { IButton, Image } from '../../types/types';

export class AuroraSectionHero extends LitElement {
  @property({})
  gradientClass: string;

  @property({ type: Object })
  data: {
    body: {
      title: string;
      description: string;
      buttons?: IButton[];
      hasSeperator?: boolean;
    };
    image: Image[];
    bgColor?: 'gradient' | 'light' | 'grey';
    alignment?: 'right';
    usePadding?: boolean;
  };

  static get styles() {
    return [styles, global, typography];
  }

  connectedCallback() {
    super.connectedCallback();
  }

  render() {
    const {
      image,
      bgColor,
      body,
      alignment,
      usePadding,
    } = this.data;

    if (image.length > 1) {
      return html `
        <section class="section-hero ${bgColor}">
          <div class="section-hero-carousel">
            <aurora-text-block .data="${{
              title: body.title,
              description: body.description,
              hasSeperator: body.hasSeperator,
              alignment: 'center',
            }}"></aurora-text-block>
            <aurora-carousel>
              ${image.map((img) => html`<aurora-img .data="${img}"></aurora-img>`)}
            </aurora-carousel>
        </section>
      `;
    } 

    return html ` 
      <section class="section-hero ${bgColor}">
        <div class="${classMap({
            'section-hero-block': true,
            ...(alignment && { [alignment]: true }),
            'optional-padding': usePadding
          })}"
        >
          ${image
            ? html`<aurora-img .data="${image[0] || image}"></aurora-img>` 
            : nothing
          }
          <aurora-text-block .data="${{
            title: body.title,
            description: body.description,
            hasSeperator: body.hasSeperator,
            primaryButton: body.buttons?.[0],
            secondaryButton: body.buttons?.[1],
          }}"></aurora-text-block>
        </div>
      </section>`;
  }
}
