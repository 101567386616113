// Created By: Joe Kanakaraj

import { html, LitElement } from 'lit';
import { property, state } from 'lit/decorators.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
// import * as LottiePlayer from '@lottiefiles/lottie-player';
// import { LottieInteractivity } from '@lottiefiles/lottie-interactivity';

// styles
import styles from './aurora-homepage-panel-css';
import global from '../../styles/global-css';

export class AuroraHomepagePanel extends LitElement {
  @property({ type: Object, attribute: false })
  data: {
    title: string;
    body: string;
  };

  @state() _loading: boolean;
  static get styles() {
    return [styles, global];
  }

  connectedCallback() {
    const attr = this.getAttribute('data');
    super.connectedCallback();

    if (attr !== null && !Object.keys(attr).includes('loaded')) {
      this.data = JSON.parse(this.getAttribute('data'));
      this.setAttribute('data', '{"loaded": true}');
    }
  }

  // firstUpdated() {
  // document.addEventListener('DOMContentLoaded', function (e) {
  //   console.log('DOMContentLoaded!!!');
  // });
  // LottieInteractivity.create({
  //   player: '#firstLottie',
  //   mode: 'scroll',
  //   actions: [
  //     {
  //       visibility: [0, 1],
  //       type: 'seek',
  //       frames: [0, 300],
  //     },
  //   ],
  // });
  // }

  @property({ type: String })
  variant: 'design' | 'document' | 'deliver' | 'accessibility' | 'living-system';
  render() {
    if (!this.variant) {
      this.variant = 'design';
    }

    const { title, body } = this.data;
    return html`
      <section class="homepage-panel ${this.variant}">
        <div class="section ">
          <!-- <div class="bg ${this.variant}"></div> -->
          <div class="section-constellation-wrapper">
            <div class="section-lottie">
              <lottie-player
                autoplay
                loop
                id="constellation"
                src="https://lottie.host/572831fe-00ff-42d7-a3db-bc5e978026fa/fNHOvZeNg8.json"
                class="constellation"
              >
              </lottie-player>
              <lottie-player
                src="https://lottie.host/bb15e745-e265-4216-a235-f120a76e67ae/eO8m4ffLKF.json"
                background="transparent"
                speed="1"
                autoplay
                loop
                class="icon-animation"
              ></lottie-player>
            </div>
          </div>
          <div class="section-content-wrapper">
            <div class="body">
              <div class="content-title">
                <h2>${title}</h2>
              </div>
              <div class="description">${unsafeHTML(body)}</div>
            </div>
          </div>
        </div>
      </section>
    `;
  }
}
