// Created By: Mike Baker

import { html, LitElement } from 'lit';
import { property, query, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';

// Styles
import styles from './aurora-tabset-css';
import global from '../../styles/global-css';
import typography from '../../styles/typography-css';

interface IPanel {
  buttonTitle: string;
  panelTitle?: string;
}

export class AuroraTabset extends LitElement {
  @state()
  private _activeTab = 1;
  @property({ type: Boolean }) isMarginZero = false;
  @property({ type: Object })
  data: {
    title?: string;
    description?: string;
    variant?: 'white' | 'gray' | 'secondary' | 'gradient';
    tabPanel: Array<IPanel>;
  };

  @property({ type: Number })
  private _scrollLeft = 0;

  @query('#tabset-container') private _tabsetContainer: HTMLElement;
  @query('#tabset-buttons') private _tabsetButtons: HTMLElement;

  static get styles() {
    return [global, styles, typography];
  }

  private handleTabClick(index: number): void {
    const button = this._tabsetButtons.children[index] as HTMLElement;
    const buttonRect = button.getBoundingClientRect();
    const containerRect = this._tabsetButtons.getBoundingClientRect();
  
    // we check if the right edge of the button is outside the right edge of the container 
    // or if the left edge of the button is outside the left edge of the container.
    if (buttonRect.right > containerRect.right) {
      this._tabsetButtons.scrollLeft += buttonRect.right - containerRect.right;
    } else if (buttonRect.left < containerRect.left) {
      this._tabsetButtons.scrollLeft -= containerRect.left - buttonRect.left;
    }

    this._activeTab = index + 1;
  }

  private handleTabKeyDown(e: KeyboardEvent): void {
    if (e.key === 'ArrowRight' && this._activeTab < this.data.tabPanel.length) {
      this._activeTab = this._activeTab + 1;
    }

    if (e.key === 'ArrowLeft' && this._activeTab > 1) {
      this._activeTab = this._activeTab - 1;
    }
  }

  private is_ActiveTab(index: number): boolean {
    return this._activeTab === index + 1;
  }

  private setActiveTabByURL(): void {
    const { hash: tabIdParam } = window.location;
    if (tabIdParam.length === 0) return;
    const preselectedTabButton: HTMLButtonElement = this.renderRoot.querySelector(tabIdParam);
    preselectedTabButton.click();
  }

  private updateURLHashByTab(): void {
    if (this.data.tabPanel.length) {
      const parsedButtonTitle = 'tab-' + this.data.tabPanel[this._activeTab-1].buttonTitle.replaceAll(/\s/g, '');
      window.location.hash = parsedButtonTitle;
    }
  }

  private scrollShadowHandler(): void {
    this._scrollLeft = this._tabsetButtons.scrollLeft;
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('resize', this.updateButtonVisibility.bind(this));
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('resize', this.updateButtonVisibility.bind(this));
  }

  protected firstUpdated(): void {
    this.setActiveTabByURL();
  }

  private isButtonsWidthLessThanContainer(): boolean {
    const containerWidth = this._tabsetContainer?.clientWidth;
    const buttonsWidth = this._tabsetButtons?.scrollWidth;
    return buttonsWidth <= containerWidth;
  }
  
  private updateIsMarginZeroFlag(): void {
    this.isMarginZero = this.isButtonsWidthLessThanContainer();
  }

  protected updated(): void {
    this.updateURLHashByTab();
    this.updateButtonVisibility();
  }

  private updateButtonVisibility(): void {
    const isHidden = String(this.isButtonsWidthLessThanContainer());
    // If there are too many free space then hide buttons
    this.shadowRoot?.querySelector('.previous-button')?.setAttribute('data-hidden', isHidden);
    this.shadowRoot?.querySelector('.next-button')?.setAttribute('data-hidden', isHidden);
    this.updateIsMarginZeroFlag();
  }
  
  private handlePrevious(): void {
    const scrollPosition = this._tabsetButtons.scrollLeft;
    const visibleButtonsWidth = this._tabsetButtons.offsetWidth;

    // Scroll left if there is space
    if (scrollPosition > 0) {
      this._tabsetButtons.scrollLeft -= visibleButtonsWidth;
    }
  }

  private handleNext(): void {
    const buttonsWidth = this._tabsetButtons.scrollWidth;
    const visibleButtonsWidth = this._tabsetButtons.offsetWidth;
    const scrollPosition = this._tabsetButtons.scrollLeft;
    
    // Scroll right if there is space
    if (scrollPosition + visibleButtonsWidth < buttonsWidth) {
      this._tabsetButtons.scrollLeft += visibleButtonsWidth;
    }
  }

  render() {
    const { title, description, variant, tabPanel } = this.data;

    return html`
      <section class="aurora-tabset ${variant}">
        ${title && html`
          <div class="tabset-header">
            <h2>${title}</h2>
            ${description && html`<span class="body-1">${description}</span>`}
          </div>
        `}
        <div
          id="tabset-container"
          class=${classMap({
            'tabset-container left-shadow right-shadow': true,
            'left-shadow__active': this._scrollLeft > 0,
            'right-shadow__active': this._scrollLeft + this._tabsetButtons?.clientWidth < this._tabsetButtons?.scrollWidth
          })}>
          <button
            class="previous-button"
            aria-label="previous-button"
            ?disabled=${this._scrollLeft === 0}
            @click=${this.handlePrevious}>
            <svg xmlns="http://www.w3.org/1300/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M31 16.5C31 24.7843 24.2843 31.5 16 31.5C7.71573 31.5 1 24.7843 1 16.5C1 8.21573 7.71573 1.5 16 1.5C24.2843 1.5 31 8.21573 31 16.5ZM32 16.5C32 25.3366 24.8366 32.5 16 32.5C7.16344 32.5 0 25.3366 0 16.5C0 7.66344 7.16344 0.5 16 0.5C24.8366 0.5 32 7.66344 32 16.5ZM18.7595 9.58166C18.5648 9.45015 18.3143 9.47981 18.1494 9.65386L12.2302 15.9038C11.9223 16.2289 11.9223 16.7711 12.2302 17.0962L18.1494 23.3461C18.3143 23.5202 18.5648 23.5499 18.7595 23.4183C19.0402 23.2286 19.0822 22.7974 18.8444 22.5463L13.118 16.5L18.8444 10.4537C19.0822 10.2026 19.0402 9.77138 18.7595 9.58166Z" fill="#888B8D"/>
            </svg>
          </button>
          <div
            id="tabset-buttons"
            class="tabset-buttons"
            class=${classMap({
              'tabset-buttons': true,
              'm-0': this.isMarginZero,
            })}
            role="tablist"
            @scroll=${this.scrollShadowHandler}>
            ${tabPanel.length && tabPanel.map((panel, index) => html`
              <button
                id="tab-${panel.buttonTitle.replaceAll(/\s/g, '')}"
                type="button"
                class="tabset-button ${this.is_ActiveTab(index) ? 'active' : ''}"
                @click="${() => this.handleTabClick(index)}"
                @keydown="${(e: KeyboardEvent) => this.handleTabKeyDown(e)}"
                role="tab"
                aria-selected="${this.is_ActiveTab(index) ? 'true' : 'false'}"
                aria-controls="tabpanel-${panel.buttonTitle.replaceAll(/\s/g, '')}"
                tabindex="${this.is_ActiveTab(index) ? '0' : '-1'}"
                >
                ${panel.buttonTitle}
              </button>
            `)}
          </div>
          <button
            class="next-button"
            aria-label="next-button"
            ?disabled=${this._scrollLeft + this._tabsetButtons?.clientWidth >= this._tabsetButtons?.scrollWidth}
            @click=${this.handleNext}>
              <svg xmlns="http://www.w3.org/1300/svg" style="-webkit-transform: rotate(0deg); transform: rotate(0deg);" width="32" height="33" viewBox="0 0 32 33" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1 16.5C1 8.21573 7.71573 1.5 16 1.5C24.2843 1.5 31 8.21573 31 16.5C31 24.7843 24.2843 31.5 16 31.5C7.71573 31.5 1 24.7843 1 16.5ZM1.39876e-06 16.5C2.17128e-06 7.66344 7.16345 0.499998 16 0.499999C24.8366 0.499999 32 7.66344 32 16.5C32 25.3366 24.8366 32.5 16 32.5C7.16344 32.5 6.26248e-07 25.3366 1.39876e-06 16.5ZM13.2405 23.4183C13.4351 23.5498 13.6857 23.5202 13.8506 23.3461L19.7698 17.0962C20.0777 16.7711 20.0777 16.2289 19.7698 15.9038L13.8506 9.65386C13.6857 9.47981 13.4352 9.45015 13.2405 9.58166C12.9598 9.77138 12.9178 10.2026 13.1556 10.4537L18.882 16.5L13.1556 22.5463C12.9178 22.7974 12.9598 23.2286 13.2405 23.4183Z" fill="#888B8D"/>
              </svg>
          </button>
        </div>
        <div class="tabset-content">
          ${tabPanel.map((panel, index) => html` 
            <div
              id="tabpanel-${panel.buttonTitle.replaceAll(/\s/g, '')}"
              class="tab-panel ${this.is_ActiveTab(index) ? 'active' : ''}"
              data-id="${index + 1}"
              ?hidden="${!this.is_ActiveTab(index)}"
              role="tabpanel"
              aria-labelledby="tab-${panel.buttonTitle.replaceAll(/\s/g, '')}"
              aria-hidden="${!this.is_ActiveTab(index)}"
              >
              <div class="tab-content">
                <slot name="${index + 1}"></slot>
              </div>
            </div>
          `)}
        </div>
      </section>
    `;
  }
}
