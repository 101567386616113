// Created By: Yasmine Jimenez
import { html, LitElement, nothing } from 'lit';
import { property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';

// Styles
import global from '../../styles/global-css';
import styles from './aurora-tile-css';

// Types
import { Image } from '../../types/types';

export class AuroraTile extends LitElement {
  @property({ type: Object })
  data: {
    title: string;
    image: Image;
    href?: string;
  };

  static get styles() {
    return [styles, global];
  }

  connectedCallback() {
    super.connectedCallback();
  }

  render() {
    const { image, title, href } = this.data;
    
      return html`
        <a href="${href ? href : '#'}" class=${classMap({"aurora-tile": true, "non-clickable": !href })}>
          <div class="image-wrapper" aria-label="${image.altText}">
            ${image ? html`<aurora-img .data="${image}"></aurora-img>` : nothing}
          </div>
          <span class="tile-title">${title}</span>
        </a>
      `
  }
  
}
