// Created By: Mateo Trujillo
import { html, LitElement, nothing } from 'lit';
import { property, state } from 'lit/decorators.js';
import { map } from 'lit/directives/map.js';
import { domPurify } from '../../utils/domPurify';

// Components
import '../../aurora-link-button';

// styles
import styles from './aurora-cta-section-css';
import global from '../../styles/global-css';

//types
import { IButton } from '../../types/types';

export class AuroraCtaSection extends LitElement {
  @property({ type: Object })
  data: {
    title: string;
    description?: string;
    buttons: Omit<IButton, 'buttonStyles'>[];
  };

  @property({ type: String })
  variant: 'default' | 'primary-light' | 'secondary-light' | 'secondary-dark';

  @state() _loading: boolean;
  static get styles() {
    return [styles, global];
  }


  get styledButtons() {
    const buttonStyles = (this.variant === 'default' || this.variant === 'secondary-dark')
      ? ['large-button']
      : ['large-button', 'dark'];

    return this.data.buttons.map(button => ({
      ...button,
      buttonStyles,
    }));
  }

  connectedCallback() {
    super.connectedCallback();
    if (!this.variant) {
      this.variant = 'default';
    }
  }

  render() {
    const { title, description } = this.data;
    const buttons = this.styledButtons;

    return html` <section class="cta-section-block ${this.variant}">
      <div class="container">
        <div class="w-full">
          <div class="title">
            <h2>${title}</h2>
          </div>
          <div class="section-body" .innerHTML=${domPurify(description)}></div>
          <div class="call-to-action">
            ${buttons?.length > 0
              ? html`
                  ${map(
                    buttons,
                    (btn: IButton) =>
                      html` <aurora-link-button
                        class="aurora-btn"
                        .buttondata="${btn}"
                      ></aurora-link-button>`,
                  )}
                `
              : nothing}
          </div>
        </div>
      </div>
    </section>`;
  }
}
